import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '6128065609895'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Length": [
      "",
      "3 / 8",
      "3 / 8",
      "3 / 8",
      "3/ 8",
      "",
      "",
      "",
      "",
    ],
    "Breast": [
      "",
      "29 / 74",
      "30 3/4 / 78",
      "32 1/4 / 82",
      "33 3/4 / 86",
      "",
      "",
      "",
      "",
    ]
  }

  return (
    <Layout>
      <SEO title="Shop - Top" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify